export const GET_ALL_HEBIT = "GET_ALL_HEBIT";
export const ADD_HEBIT = "ADD_HEBIT";
export const GET_HABIT_BY_ID = "GET_HABIT_BY_ID";
export const DELETE_HABIT_BY_ID = "DELETE_HABIT_BY_ID";
export const DELETE_HABIT_ERROR = "DELETE_HABIT_ERROR";
export const UPDATE_HABIT_BY_ID = "UPDATE_HABIT_BY_ID";
export const HABIT_GET_ERROR = "HABIT_GET_ERROR";
export const UPDATE_HABIT_ERROR = "UPDATE_HABIT_ERROR";
export const GET_HABIT_REQUEST = "GET_HABIT_REQUEST";
export const DELETE_HABIT_REQUEST = "DELETE_HABIT_REQUEST";
export const UPDATE_HABIT_REQUEST = "UPDATE_HABIT_REQUEST";
export const ADD_HABBIT_ERROR = "ADD_HABBIT_ERROR";

export const GET_MEDIA = "GET_MEDIA";
export const MEDIA_GET_ERROR = "UPDATE_HABIT_ERROR";
export const GET_MEDIA_REQUEST = "GET_MEDIA_REQUEST";
export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_ERROR = "ADD_MEDIA_ERROR";
export const GET_MEDIA_BY_ID = "GET_MEDIA_BY_ID";
export const UPDATE_MEDIA_REQUEST = "UPDATE_MEDIA_REQUEST";
export const UPDATE_MEDIA_BY_ID = "UPDATE_MEDIA_BY_ID";
export const UPDATE_MEDIA_ERROR = "UPDATE_MEDIA_ERROR";
export const DELETE_MEDIA_BY_ID = "DELETE_MEDIA_BY_ID";
export const DELETE_MEDIA_ERROR = "DELETE_MEDIA_ERROR";
export const DELETE_MEDIA_REQUEST = "DELETE_MEDIA_REQUEST";

export const GET_ALL_LANDING = "GET_ALL_LANDING";
export const GET_LANDING_REQUEST = "GET_LANDING_REQUEST";
export const LANDING_GET_ERROR = "LANDING_GET_ERROR";
export const ADD_LANDING_ERROR = "ADD_LANDING_ERROR";
export const ADD_LANDING = "ADD_LANDING";
export const GET_LANDING_BY_ID = "GET_LANDING_BY_ID";
export const UPDATE_LANDING_REQUEST = "UPDATE_LANDING_REQUEST";
export const UPDATE_LANDING_BY_ID = "UPDATE_LANDING_BY_ID";
export const UPDATE_LANDING_ERROR = "UPDATE_LANDING_ERROR";
export const DELETE_LANDING_BY_ID = "DELETE_LANDING_BY_ID";
export const DELETE_LANDING_ERROR = "DELETE_LANDING_ERROR";
export const DELETE_LANDING_REQUEST = "DELETE_LANDING_REQUEST";

export const GET_ALL_OURTEAM = "GET_ALL_OURTEAM";
export const GET_OURTEAM_REQUEST = "GET_OURTEAM_REQUEST";
export const OURTEAM_GET_ERROR = "OURTEAM_GET_ERROR";
export const ADD_OURTEAM_ERROR = "ADD_OURTEAM_ERROR";
export const ADD_OURTEAM = "ADD_OURTEAM";
export const GET_OURTEAM_BY_ID = "GET_OURTEAM_BY_ID";
export const UPDATE_OURTEAM_REQUEST = "UPDATE_OURTEAM_REQUEST";
export const UPDATE_OURTEAM_BY_ID = "UPDATE_OURTEAM_BY_ID";
export const UPDATE_OURTEAM_ERROR = "UPDATE_OURTEAM_ERROR";
export const DELETE_OURTEAM_BY_ID = "DELETE_OURTEAM_BY_ID";
export const DELETE_OURTEAM_ERROR = "DELETE_OURTEAM_ERROR";
export const DELETE_OURTEAM_REQUEST = "DELETE_OURTEAM_REQUEST";

export const GET_ALL_SUBSCRIBER = "GET_ALL_SUBSCRIBER";
export const GET_SUBSCRIBER_REQUEST = "GET_SUBSCRIBER_REQUEST";
export const SUBSCRIBER_GET_ERROR = "SUBSCRIBER_GET_ERROR";

export const GET_ALL_BIOWASTE = "GET_ALL_BIOWASTE";
export const GET_BIOWASTE_REQUEST = "GET_BIOWASTE_REQUEST";
export const BIOWASTE_GET_ERROR = "BIOWASTE_GET_ERROR";
export const ADD_BIOWASTE_ERROR = "ADD_BIOWASTE_ERROR";
export const ADD_BIOWASTE = "ADD_BIOWASTE";
export const GET_BIOWASTE_BY_ID = "GET_BIOWASTE_BY_ID";
export const UPDATE_BIOWASTE_REQUEST = "UPDATE_BIOWASTE_REQUEST";
export const UPDATE_BIOWASTE_BY_ID = "UPDATE_BIOWASTE_BY_ID";
export const UPDATE_BIOWASTE_ERROR = "UPDATE_BIOWASTE_ERROR";
export const DELETE_BIOWASTE_BY_ID = "DELETE_BIOWASTE_BY_ID";
export const DELETE_BIOWASTE_ERROR = "DELETE_BIOWASTE_ERROR";
export const DELETE_BIOWASTE_REQUEST = "DELETE_BIOWASTE_REQUEST";

export const GET_ALL_APPOINTMENT = "GET_ALL_APPOINTMENT";
export const GET_APPOINTMENT_REQUEST = "GET_APPOINTMENT_REQUEST";
export const APPOINTMENT_GET_ERROR = "APPOINTMENT_GET_ERROR";

export const GET_ALL_PRESCRIPTION = "GET_ALL_PRESCRIPTION";
export const GET_PRESCRIPTION_REQUEST = "GET_PRESCRIPTION_REQUEST";
export const PRESCRIPTION_GET_ERROR = "PRESCRIPTION_GET_ERROR";

export const GET_ALL_CONTACT = "GET_ALL_CONTACT";
export const GET_CONTACT_REQUEST = "GET_CONTACT_REQUEST";
export const CONTACT_GET_ERROR = "CONTACT_GET_ERROR";

export const GET_ALL_APPLIEDJOB = "GET_ALL_APPLIEDJOB";
export const GET_APPLIEDJOB_REQUEST = "GET_APPLIEDJOB_REQUEST";
export const APPLIEDJOB_GET_ERROR = "APPLIEDJOB_GET_ERROR";
